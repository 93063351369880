@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header {
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.container {
  display: flex;
  align-items: center;
}

.card {
  background-color: #fff;
  color: #333;
  border-radius: 15px;
  padding: 40px 50px;
  margin: 20px 0;
  position: relative;
}

.card.reverse {
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
}

.card h2 {
  font-size: 22px;
  font-weight: 600;
  text-align: center;
}

.card h5 {
  text-align: center;
}

ul {
  list-style: none;
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
}

.letter {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4px 0;
}

.letter li {
  position: relative;
  min-width: 50px;
  width: 50px;
  height: 50px;
  padding: 8px 3px;
  text-align: center;
  border-radius: 20%;
  border: 1px #eee solid;
  transition: 0.3s;
}

.letter input {
  /* display: flex;
  align-items: center;
  justify-content: center; */
  background: transparent;
  color: #fff;
  text-align: center;
  font-size: 20px;
  width: 40px;
  height: 30px;
}

.button-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 10px;
}

input {
  flex-grow: 2;
  border: none;
  font-size: 16px;
}

input:focus {
  outline: none;
}

.btn {
  color: #fff;
  border: 0;
  border-radius: 8px;
  color: #fff;
  width: 100px;
  height: 40px;
  cursor: pointer;
  margin: 0px 4px;
}

.btn-primary {
  background-color: #202142;
}

.btn-secondary {
  background: #ff6a95;
}

.btn:hover {
  transform: scale(0.98);
  opacity: 0.9;
}

.btn:disabled {
  background-color: #cccccc;
  color: #333;
  cursor: auto;
}

.btn:disabled:hover {
  transform: scale(1);
  opacity: 1;
}

.message {
  padding-top: 10px;
  text-align: center;
  color: rebeccapurple;
}


